/* eslint-disable */

<template>
  <div class="vx-col">
    <vx-card class="mb-base">
      <div class="">
        <div class="simulation-10">
          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-public-network-2 rounded" @click="wifi_controller.active = !wifi_controller.active">
                <div class="tenth-public-network-bottom-2">
                  <div class="tenth-block rounded">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">WiFI<br />Controller<br /></h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-sim-legacy-col w-sim-legacy-col-3" />
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-internet-block-2 rounded">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/global.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Internet</h1>
              </div>
            </div>
            <div class="w-sim-legacy-col w-sim-legacy-col-3" />
          </div>

          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="line-internet-up"></div>
              <div class="internet-connect-right-line"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-block rounded" style="background-color: #0285e4">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                    width="50"
                  />
                </div>
              </div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-firewall-block-2" style="background-color: #4169e1">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/wall-2.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Firewall</h1>
              </div>
              <div class="line-internet-up"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-server rounded bg-danger" @click="wireless_client.active = !wireless_client.active">
                <div class="tenth-public-network-bottom-2 rounded">
                  <div class="tenth-block rounded">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Wireless<br />Client</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-public-network-bottom-2 rounded" @click="radius_server.active = !radius_server.active">
                <div class="tenth-public-network-2 rounded">
                  <div class="tenth-block rounded">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Radius<br />Server</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-sim-legacy-col w-sim-legacy-col-3" />
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3 bg">
              <div class="tenth-internet-block-2 bg-warning rounded">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/wireless-access-point.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">WAP</h1>
              </div>
            </div>
            <div class="w-sim-legacy-col w-sim-legacy-col-3" />
          </div>
        </div>
      </div>
    </vx-card>

    <movable-popup
      refVal="movableWiFiControllerRef"
      title="WiFi Controller - Drag Here To Move"
      :active="wifi_controller.active"
      v-on:close="wifi_controller.active = false"
    >
      <template v-slot:content>
        <div v-for="field in wifi_controller.properties" :key="field.name" class="vx-row mb-3 mt-3">
          <div class="vx-col flex w-1/3">
            <h3 class="break-words self-center">{{ field.name }}</h3>
          </div>
          <div class="vx-col w-2/3">
            <v-select
              v-if="field.type === 'dropdown'"
              :options="field.options"
              v-model="field.value"
              :disabled="field.disabled"
              class="w-full self-center"
              placeholder="Select Option From Dropdown..."
            />
            <vs-input v-if="field.type === 'text'" v-model="field.value" :readonly="field.disabled" class="inputx w-full self-center" />
            <div class="flex items-center" v-if="field.type === 'switch'">
              <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
            </div>
            <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
            <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableRadiusServerRef"
      title="Radius Server - Drag Here To Move"
      :active="radius_server.active"
      v-on:close="radius_server.active = false"
    >
      <template v-slot:content>
        <div v-for="field in radius_server.properties" :key="field.name" class="vx-row mb-3 mt-3">
          <div class="vx-col flex w-1/3">
            <h3 class="break-words self-center">{{ field.name }}</h3>
          </div>
          <div class="vx-col w-2/3">
            <v-select
              v-if="field.type === 'dropdown'"
              :options="field.options"
              v-model="field.value"
              :disabled="field.disabled"
              class="w-full self-center"
              placeholder="Select Option From Dropdown..."
            />
            <vs-input v-if="field.type === 'text'" v-model="field.value" :readonly="field.disabled" class="inputx w-full self-center" />
            <div class="flex items-center" v-if="field.type === 'switch'">
              <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
            </div>
            <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
            <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableRadiusServerRef"
      title="Wireless Client - Drag Here To Move"
      :active="wireless_client.active"
      v-on:close="wireless_client.active = false"
    >
      <template v-slot:content>
        <div v-for="field in wireless_client.properties" :key="field.name" class="vx-row mb-3 mt-3">
          <div class="vx-col flex w-1/3">
            <h3 class="break-words self-center">{{ field.name }}</h3>
          </div>
          <div class="vx-col w-2/3">
            <v-select
              v-if="field.type === 'dropdown'"
              :options="field.options"
              v-model="field.value"
              :disabled="field.disabled"
              class="w-full self-center"
              placeholder="Select Option From Dropdown..."
            />
            <vs-input v-if="field.type === 'text'" v-model="field.value" :readonly="field.disabled" class="inputx w-full self-center" />
            <div class="flex items-center" v-if="field.type === 'switch'">
              <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
            </div>
            <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
            <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
          </div>
        </div>
        -
      </template>
    </movable-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      wifi_controller: {
        active: false,
        properties: [
          {
            name: 'SSID',
            type: 'text',
            value: '',
            answer: 'CORPGUEST',
            disabled: false,
            score: null,
          },
          {
            name: 'Shared Key',
            type: 'text',
            value: '',
            answer: 'SECRET',
            disabled: false,
            score: null,
          },
          {
            name: 'AAA Server IP',
            type: 'text',
            value: '',
            answer: '192.168.1.20',
            disabled: false,
            score: null,
          },
          {
            name: 'PSK',
            type: 'text',
            value: '',
            answer: 'SECRET',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'WPA2-PSK',
            disabled: false,
            options: ['OPEN', 'WPA-EAP-PEAP-MSCHAPV2', 'WPA2-EAP-PEAP-MSCHAPV2', 'WPA-PSK', 'WPA2-PSK', 'WEP'],
            score: null,
          },
          {
            name: 'Controller IP',
            type: 'text',
            value: '192.168.1.10',
            answer: '192.168.1.10',
            disabled: true,
            score: null,
          },
        ],
      },

      radius_server: {
        active: false,
        properties: [
          {
            name: 'Shared Key',
            type: 'text',
            value: 'SECRET',
            answer: 'SECRET',
            disabled: true,
            score: null,
          },
          {
            name: 'Client IP',
            type: 'text',
            value: '',
            answer: '192.168.1.10',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'Active Directory',
            disabled: false,
            options: ['Active Directory', 'LOCAL', 'MSSQL'],
            score: null,
          },
          {
            name: 'Server IP',
            type: 'text',
            value: '192.168.1.20',
            answer: '192.168.1.20',
            disabled: true,
            score: null,
          },
        ],
      },

      wireless_client: {
        active: false,
        properties: [
          {
            name: 'SSID',
            type: 'text',
            value: 'CORPGUEST',
            answer: 'CORPGUEST',
            disabled: false,
            score: null,
          },
          {
            name: 'Username',
            type: 'text',
            value: '',
            answer: 'guest01',
            disabled: false,
            score: null,
          },
          {
            name: 'User Password',
            type: 'text',
            value: '',
            answer: 'guestpass',
            disabled: false,
            score: null,
          },
          {
            name: 'PSK',
            type: 'text',
            value: '',
            answer: 'SECRET',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'WPA2-ENTERPRISE',
            disabled: false,
            options: ['WPA-ENTERPRISE', 'WEP', 'OPEN', 'WPA2-PSK', 'WPA-PSK', 'WPA2-ENTERPRISE'],
            score: null,
          },
        ],
      },
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.wifi_controller.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.wifi_controller.properties[field_i].score = true;
          totalScore++;
        } else {
          this.wifi_controller.properties[field_i].score = false;
        }
      });

      this.radius_server.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.radius_server.properties[field_i].score = true;
          totalScore++;
        } else {
          this.radius_server.properties[field_i].score = false;
        }
      });

      this.wireless_client.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.wireless_client.properties[field_i].score = true;
          totalScore++;
        } else {
          this.wireless_client.properties[field_i].score = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.w-select {
  font-weight: 800;
}

@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx249112018676613120.scss';
</style>
